document.addEventListener("DOMContentLoaded", () => {
  couponClick();
})

function couponClick(){
  let couponElements = document.querySelectorAll(".click_coupons_code");
  couponElements.forEach(element => {
    element.addEventListener("click", function (event) {
      // Get data attributes
      let coupon_type = this.dataset.coupon_type;
      let value = this.dataset.href;
      let topic_url = this.dataset.hrefAlt;

      if (value !== null && topic_url !== null) {
        if (!coupon_type) {
          window.open(topic_url);
          window.location.href = value;
        } else {
          window.open(value);
          window.location.href = topic_url;
        }
        window.focus();
      }
    });
  });
}

window.couponClick = couponClick; 